import React from 'react';
import Main from '../Main/Main';
import Footer from '../Footer/Footer';
import GoToTop from '../Go-to-top/Go-to-top';
import Header from '../Header/Header';

function Home() {
  return (
    <>
      <Header />
      <Main />
      <Footer />
      <GoToTop />
    </>
  );
}

export default Home;
