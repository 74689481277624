import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import TrailerSlide from '../Trailer-slide/Trailer-slide';
import TrailerText from '../Trailer-text/Trailer-text';
import {
  MAXIMUM_SLIDES_COUNT,
} from '../../const';

import './Trailer.css';

function Trailer({ slides }) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const createNewTrailer = (index) => {
    setCurrentSlideIndex(index);
  };

  return (
    <div className="trailers">
      <div className="container">
        <div className="row ipad-width">
          <div className="col-md-12">
            <div className="title-hd">
              <h2>Скоро в кино</h2>
            </div>
            <div className="videos">
              <div className="trailer-slide-iframe">
                <TrailerSlide
                  trailer={slides[currentSlideIndex].trailers.youtube}
                  key={slides[currentSlideIndex].id}
                />
              </div>
              <Slider
                infinite
                vertical
                focusOnSelect
                className="slider-nav-2 thumb-ft"
                slidesToShow={MAXIMUM_SLIDES_COUNT}
                beforeChange={(current, next) => createNewTrailer(next)}
              >
                {slides.map((filmInfo) => (
                  <TrailerText
                    filmInfo={filmInfo}
                    key={filmInfo.id}
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trailer;

Trailer.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape).isRequired,
};
