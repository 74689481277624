import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MainSidebar from '../Main-sidebar/Main-sidebar';
import { Links } from '../../const';

function ActorsGrid({ actors }) {
  const actorsId = Object.keys(actors);
  const { ACTORS_IMG_LINK, ACTOR_LINK } = Links;

  return (
    <div
      className="page-single"
      style={{
        flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}
    >
      <div className="container">
        <div className="row ipad-width2">
          <div className="col-md-9 col-sm-12 col-xs-12">
            <div className="celebrity-items">
              {actorsId.map((actor) => {
                const { actorId, ruName, career } = actors[actor];
                return (
                  <div className="ceb-item" key={actorId}>
                    <Link to={`${ACTOR_LINK}${actorId}`}>
                      <img
                        src={`${ACTORS_IMG_LINK}${actorId}.jpg`}
                        alt={ruName}
                        style={{
                          height: '400px', width: 'auto', maxHeight: '400px', maxWidth: '270px', objectFit: 'cover', objectPosition: 'center top',
                        }}
                      />
                    </Link>
                    <div className="ceb-infor">
                      <h2>
                        <Link to={`${ACTOR_LINK}${actorId}`}>
                          {ruName}
                        </Link>
                      </h2>
                      <span>{career}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-3 col-xs-12 col-sm-12">
            <MainSidebar />
          </div>
        </div>
      </div>
    </div>
  );
}

ActorsGrid.propTypes = {
  actors: PropTypes.shape().isRequired,
};

export default ActorsGrid;
