/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Links,
  LoadingStatuses,
  CATEGORY_VIDEOS_GRID_COUNT,
} from '../../const';
import { generateVideoInfoObjectWithDefaultParams } from '../../util';
import {
  getCurrentCategory,
  getCurrentCategoryVideos,
  getCurrentCategoryVideosCount,
  getCurrentCategoryVideosLoadingStatus,
  getCurrentCategoryVideosPage,
} from '../../store/selectors';
import { fetchCurrentCategoryVideos } from '../../store/api-actions';
import LoadingScreen from '../Loading-screen/Loading-screen';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';

function MainGrid() {
  const { VIDEO_LINK } = Links;
  const dispatch = useDispatch();
  const currentVideosLoadingStatus = useSelector(getCurrentCategoryVideosLoadingStatus);
  const currentCategoryVideos = useSelector(getCurrentCategoryVideos);
  const currentCategory = useSelector(getCurrentCategory);
  const lastFetchedPage = useSelector(getCurrentCategoryVideosPage);
  const allVideosCount = useSelector(getCurrentCategoryVideosCount);

  useEffect(() => {
    if (currentVideosLoadingStatus === LoadingStatuses.INIT) {
      dispatch(fetchCurrentCategoryVideos(currentCategory.id, {
        start: 0,
        end: CATEGORY_VIDEOS_GRID_COUNT,
        order: 'DESC',
        sort: 'kpVotes',
        page: lastFetchedPage,
      }));
    }
  }, [dispatch, currentVideosLoadingStatus, currentCategory, lastFetchedPage]);

  function fetchMoreListItems() {
    if ((lastFetchedPage + 2) * CATEGORY_VIDEOS_GRID_COUNT <= Number(allVideosCount)) {
      if (currentVideosLoadingStatus !== LoadingStatuses.LOADING) {
        dispatch(fetchCurrentCategoryVideos(currentCategory.id, {
          start: CATEGORY_VIDEOS_GRID_COUNT * (lastFetchedPage + 1),
          end: CATEGORY_VIDEOS_GRID_COUNT * (lastFetchedPage + 2),
          order: 'DESC',
          sort: 'kpVotes',
          page: lastFetchedPage + 1,
        }));
      }
    }
    setIsFetching(false);
  }

  const [, setIsFetching] = useInfiniteScroll(fetchMoreListItems);

  return (
    <div
      className="page-single"
      style={{
        flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="flex-wrap-movielist mv-grid-fw">
              {currentCategoryVideos.map((film) => {
                const filmData = generateVideoInfoObjectWithDefaultParams(film);
                return (
                  <div className="movie-item-style-2 movie-item-style-1" key={filmData.id}>
                    <img
                      width="170"
                      height="261"
                      src={filmData.poster}
                      alt={filmData.title}
                      style={{
                        width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center top',
                      }}
                    />
                    <div className="hvr-inner">
                      <Link to={`${VIDEO_LINK}${filmData.id}`}>
                        {' '}
                        Подробнее
                        <i className="ion-android-arrow-dropright" />
                      </Link>
                    </div>
                    <div className="mv-item-infor">
                      <h6><Link to={`${VIDEO_LINK}${filmData.id}`}>{filmData.title}</Link></h6>
                      <p className="rate">
                        <i className="ion-android-star" />
                        <span>{filmData.kpRating}</span>
                        {' '}
                        /10
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            {currentVideosLoadingStatus === LoadingStatuses.LOADING && <LoadingScreen />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainGrid;
