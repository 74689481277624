/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import {
  loadCategories,
  loadNews,
  setNewsLoadingStatus,
  loadRecentNews,
  setRecentNewsLoadingStatus,
  loadNewsEntry,
  setNewsEntryLoadingStatus,
  setCurrentCategory,
  setCurrentTrailer,
  resetCurrentTrailer,
  toggleDisplayForm,
  loadDigitalReleases,
  loadSoonReleases,
  loadBoxOffice,
  toggleDisplayContentRoll,
  loadActors,
  setCategoriesLoadingStatus,
  loadSliderVideos,
  setSliderVideosLoadingStatus,
  loadMainSliderVideos,
  setMainSliderVideosLoadingStatus,
  loadCurrentCategory,
  setCurrentCategoryLoadingStatus,
  loadCurrentCategoryVideos,
  setCurrentCategoryVideosLoadingStatus,
  resetCurrentCategoryVideos,
  loadCurrentVideo,
  setCurrentVideoLoadingStatus,
} from './action';
import { LoadingStatuses } from '../const';

const initialState = {
  categories: {
    data: [],
    loadingStatus: LoadingStatuses.INIT,
    count: 0,
  },
  currentVideo: {
    data: {},
    loadingStatus: LoadingStatuses.INIT,
  },
  videos: {},
  releases: {
    soon: {},
    digital: {},
    boxOffice: {},
  },
  trailer: {
    isOpened: false,
    trailerUrl: '',
  },
  news: {
    data: [],
    loadingStatus: LoadingStatuses.INIT,
    count: 0,
    page: 0,
  },
  recentNews: {
    data: [],
    loadingStatus: LoadingStatuses.INIT,
  },
  currentNewsEntry: {
    data: {},
    loadingStatus: LoadingStatuses.INIT,
  },
  isOpenedContactForm: false,
  isOpenedContentRoll: false,
  actors: {},
  sliderVideos: [
    {
      data: [],
      loadingStatus: LoadingStatuses.INIT,
      count: 0,
    },
    {
      data: [],
      loadingStatus: LoadingStatuses.INIT,
      count: 0,
    },
    {
      data: [],
      loadingStatus: LoadingStatuses.INIT,
      count: 0,
    },
  ],
  mainSliderVideos: {
    data: [],
    loadingStatus: LoadingStatuses.INIT,
  },
  currentCategory: {
    data: {
      id: '',
      name: '',
    },
    loadingStatus: LoadingStatuses.INIT,
  },
  currentCategoryVideos: {
    data: [],
    loadingStatus: LoadingStatuses.INIT,
    count: 0,
    page: 0,
  },
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadCategories, (state, action) => {
      state.categories.data = action.payload.data;
      state.categories.count = action.payload.count;
    })
    .addCase(setCategoriesLoadingStatus, (state, action) => {
      state.categories.loadingStatus = action.payload;
    })
    .addCase(loadNews, (state, action) => {
      state.news.data = action.payload.data;
      state.news.count = action.payload.count;
      state.news.page = action.payload.page;
    })
    .addCase(setNewsLoadingStatus, (state, action) => {
      state.news.loadingStatus = action.payload;
    })
    .addCase(loadRecentNews, (state, action) => {
      state.recentNews.data = action.payload;
    })
    .addCase(setRecentNewsLoadingStatus, (state, action) => {
      state.recentNews.loadingStatus = action.payload;
    })
    .addCase(loadNewsEntry, (state, action) => {
      state.currentNewsEntry.data = action.payload;
    })
    .addCase(setNewsEntryLoadingStatus, (state, action) => {
      state.currentNewsEntry.loadingStatus = action.payload;
    })
    .addCase(loadDigitalReleases, (state, action) => {
      state.releases.digital = action.payload;
    })
    .addCase(loadSoonReleases, (state, action) => {
      state.releases.soon = action.payload;
    })
    .addCase(loadBoxOffice, (state, action) => {
      state.releases.boxOffice = action.payload;
    })
    .addCase(setCurrentCategory, (state, action) => {
      state.currentCategory = action.payload;
    })
    .addCase(setCurrentTrailer, (state, action) => {
      state.trailer.trailerUrl = action.payload;
      state.trailer.isOpened = true;
    })
    .addCase(resetCurrentTrailer, (state) => {
      state.trailer.trailerUrl = '';
      state.trailer.isOpened = false;
    })
    .addCase(toggleDisplayForm, (state) => {
      state.isOpenedContactForm = !state.isOpenedContactForm;
    })
    .addCase(toggleDisplayContentRoll, (state) => {
      state.isOpenedContentRoll = !state.isOpenedContentRoll;
    })
    .addCase(loadActors, (state, action) => {
      state.actors = action.payload;
    })
    .addCase(loadSliderVideos, (state, action) => {
      state.sliderVideos[action.payload.id].data = action.payload.videos.data;
      state.sliderVideos[action.payload.id].count = action.payload.videos.count;
    })
    .addCase(setSliderVideosLoadingStatus, (state, action) => {
      state.sliderVideos[action.payload.id].loadingStatus = action.payload.status;
    })
    .addCase(loadMainSliderVideos, (state, action) => {
      state.mainSliderVideos.data = action.payload.data;
    })
    .addCase(setMainSliderVideosLoadingStatus, (state, action) => {
      state.mainSliderVideos.loadingStatus = action.payload;
    })
    .addCase(loadCurrentCategory, (state, action) => {
      state.currentCategory.data = action.payload.data;
    })
    .addCase(setCurrentCategoryLoadingStatus, (state, action) => {
      state.currentCategory.loadingStatus = action.payload;
    })
    .addCase(loadCurrentCategoryVideos, (state, action) => {
      state.currentCategoryVideos.data = [
        ...state.currentCategoryVideos.data,
        ...action.payload.data,
      ];
      state.currentCategoryVideos.count = action.payload.count;
      state.currentCategoryVideos.page = action.payload.page;
    })
    .addCase(setCurrentCategoryVideosLoadingStatus, (state, action) => {
      state.currentCategoryVideos.loadingStatus = action.payload;
    })
    .addCase(resetCurrentCategoryVideos, (state) => {
      state.currentCategoryVideos.data = [];
      state.currentCategoryVideos.count = 0;
      state.currentCategoryVideos.page = 0;
      state.currentCategoryVideos.loadingStatus = LoadingStatuses.INIT;
    })
    .addCase(loadCurrentVideo, (state, action) => {
      state.currentVideo.data = action.payload.data;
    })
    .addCase(setCurrentVideoLoadingStatus, (state, action) => {
      state.currentVideo.loadingStatus = action.payload;
    });
});

export default reducer;
