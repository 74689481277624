export const getCategories = (state) => state.categories.data;
export const getCategoriesCount = (state) => state.categories.count;
export const getCategoriesLoadingStatus = (state) => state.categories.loadingStatus;

export const getMainSliderVideos = (state) => state.mainSliderVideos.data;
export const getMainSliderLoadingStatus = (state) => state.mainSliderVideos.loadingStatus;

export const getSliderVideos = (state) => state.sliderVideos;

export const getCurrentVideo = (state) => state.currentVideo.data;
export const getCurrentVideoLoadingStatus = (state) => state.currentVideo.loadingStatus;

export const getActors = (state) => state.actors;
export const getDigitalReleases = (state) => state.releases.digital;
export const getSoonReleases = (state) => state.releases.soon;
export const getBoxOffice = (state) => state.releases.boxOffice;
export const getNews = (state) => state.news.data;
export const getNewsCount = (state) => state.news.count;
export const getNewsPage = (state) => state.news.page;
export const getNewsLoadingStatus = (state) => state.news.loadingStatus;
export const getRecentNews = (state) => state.recentNews.data;
export const getRecentNewsLoadingStatus = (state) => state.recentNews.loadingStatus;
export const getNewsEntry = (state) => state.currentNewsEntry.data;
export const getNewsEntryLoadingStatus = (state) => state.currentNewsEntry.loadingStatus;
export const getCurrentTrailerUrl = (state) => state.trailer.trailerUrl;
export const getCurrentTrailerStatus = (state) => state.trailer.isOpened;
export const getOpenedContactFormStatus = (state) => state.isOpenedContactForm;
export const getOpenedContentRollStatus = (state) => state.isOpenedContentRoll;

export const getCurrentCategory = (state) => state.currentCategory.data;
export const getCurrentCategoryLoadingStatus = (state) => state.currentCategory.loadingStatus;

export const getCurrentCategoryVideos = (state) => state.currentCategoryVideos.data;
export const getCurrentCategoryVideosCount = (state) => state.currentCategoryVideos.count;
export const getCurrentCategoryVideosPage = (state) => state.currentCategoryVideos.page;
export const getCurrentCategoryVideosLoadingStatus = (state) => state
  .currentCategoryVideos
  .loadingStatus;
