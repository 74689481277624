/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentCategoryVideos,
  getCurrentCategoryVideosCount,
  getCurrentCategoryVideosLoadingStatus,
  getCurrentCategoryVideosPage,
} from '../../store/selectors';
import {
  Links,
  RECOMMENDED_VIDEOS_COUNT,
  LoadingStatuses,
} from '../../const';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { fetchCurrentCategoryVideos } from '../../store/api-actions';
import { generateVideoInfoObjectWithDefaultParams } from '../../util';
import LoadingScreen from '../Loading-screen/Loading-screen';

function VideoSimilar({ category }) {
  const { VIDEO_LINK } = Links;
  const dispatch = useDispatch();
  const currentVideosLoadingStatus = useSelector(getCurrentCategoryVideosLoadingStatus);
  const currentCategoryVideos = useSelector(getCurrentCategoryVideos);

  const lastFetchedPage = useSelector(getCurrentCategoryVideosPage);
  const allVideosCount = useSelector(getCurrentCategoryVideosCount);

  useEffect(() => {
    if (currentVideosLoadingStatus === LoadingStatuses.INIT) {
      dispatch(fetchCurrentCategoryVideos(category, {
        start: 0,
        end: RECOMMENDED_VIDEOS_COUNT,
        order: 'DESC',
        sort: 'kpVotes',
        page: lastFetchedPage,
      }));
    }
  }, [dispatch, currentVideosLoadingStatus, category, lastFetchedPage]);

  function fetchMoreListItems() {
    if ((lastFetchedPage + 2) * RECOMMENDED_VIDEOS_COUNT <= Number(allVideosCount)) {
      if (currentVideosLoadingStatus !== LoadingStatuses.LOADING) {
        dispatch(fetchCurrentCategoryVideos(category, {
          start: RECOMMENDED_VIDEOS_COUNT * (lastFetchedPage + 1),
          end: RECOMMENDED_VIDEOS_COUNT * (lastFetchedPage + 2),
          order: 'DESC',
          sort: 'kpVotes',
          page: lastFetchedPage + 1,
        }));
      }
    }
    setIsFetching(false);
  }

  const [, setIsFetching] = useInfiniteScroll(fetchMoreListItems);

  return (
    <div className="tab-content">
      <div id="moviesrelated" className="tab active">
        <div className="row" style={{ paddingLeft: '20px' }}>
          {currentCategoryVideos.map((film) => {
            const {
              title,
              shortDescription,
              id,
              year,
              poster,
              kpRating,
              duration,
              ageLimit,
            } = generateVideoInfoObjectWithDefaultParams(film);

            return (
              <div className="movie-item-style-2" key={id}>
                <img src={poster} alt={title} style={{ maxWidth: '300px' }} />
                <div className="mv-item-infor">
                  <h6>
                    <Link to={`${VIDEO_LINK}${id}`}>
                      {title}
                      <span>{` (${year})`}</span>
                    </Link>
                  </h6>
                  <p className="rate">
                    <i className="ion-android-star" />
                    <span>{kpRating}</span>
                    {' '}
                    /10
                  </p>
                  <p className="describe">{`${shortDescription}...`}</p>
                  <p className="run-time">
                    {`Продолжительность, минут: ${duration}`}
                    <span>{`Рейтинг: ${ageLimit}+`}</span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        {currentVideosLoadingStatus === LoadingStatuses.LOADING && <LoadingScreen />}
      </div>
    </div>
  );
}

export default VideoSimilar;

VideoSimilar.propTypes = {
  category: PropTypes.string.isRequired,
};
