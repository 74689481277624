/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Links,
  OutsideLinks,
} from '../../const';
import { generateStarRating, generateVideoInfoObjectWithDefaultParams } from '../../util';
import './Video-details.css';
import { getCategories } from '../../store/selectors';
import VideoSimilar from '../Video-similar/Video-similar';

function VideoDetails({ currentVideo }) {
  const categories = useSelector(getCategories);
  const {
    genres,
    countries,
    enTitle,
    trailers,
    title,
    year,
    fullDescription,
    shortDescription,
    duration,
    ageLimit,
    id,
    kpRating,
    poster,
  } = generateVideoInfoObjectWithDefaultParams(currentVideo);
  const trailerEmbed = trailers.youtube.length !== 0 ? trailers.youtube[0].link.split('?v=')[1] : 'eRkvJ71O2yc';
  const {
    CATALOG_LINK,
  } = Links;

  const { YOUTUBE_EMBED } = OutsideLinks;

  const [offset, setOffset] = useState(0);
  const checkScrollTop = () => {
    if (window.innerWidth > 990) {
      setOffset(window.pageYOffset);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return function cleanup() {
      window.removeEventListener('scroll', checkScrollTop);
    };
  });

  const stars = generateStarRating(kpRating, <i className="ion-ios-star" />, <i className="ion-ios-star-outline" />);

  return (
    <div
      className="page-single movie-single movie_single"
      style={{
        flex: '1', marginTop: '0', paddingTop: '100px',
      }}
    >
      <div className="container">
        <div className="row ipad-width2">
          <div className="col-md-4 col-sm-12 col-xs-12">
            <div className="movie-img sticky-sb" style={{ top: offset }}>
              <img src={poster} alt={title} />
            </div>
          </div>
          <div className="col-md-8 col-sm-12 col-xs-12">
            <div className="movie-single-ct main-content">
              <h1 className="bd-hd">
                {`${title}  `}
                <span>{`${enTitle} `}</span>
                <span>{year}</span>
              </h1>
              <p>
                {countries.join(', ')}
              </p>
              <div className="movie-rate">
                <div className="rate">
                  {stars.map((star, index) => <div key={index}>{star}</div>)}
                  <p>
                    <span>{kpRating}</span>
                    {' '}
                    /10
                  </p>
                </div>
              </div>
              <div className="col-md-8 col-sm-12 col-xs-12">
                <p>{shortDescription}</p>
              </div>
              <iframe
                title="Trailer Player"
                id="fancybox-frame1632236126826"
                name="fancybox-frame1632236126826"
                className="trailer-player"
                frameBorder="0"
                vspace="0"
                hspace="0"
                webkitallowfullscreen=""
                mozallowfullscreen=""
                allowFullScreen
                allow="autoplay"
                scrolling="auto"
                src={`${YOUTUBE_EMBED}${trailerEmbed}?autoplay=1&mute=1`}
              />
              <div className="movie-tabs">
                <div className="tabs">
                  <ul className="tab-links tabs-mv">
                    <li className="active">
                      <button
                        type="button"
                      >
                        Описание
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div id="overview" className="tab active">
                      <div className="row">
                        <div className="col-md-8 col-sm-12 col-xs-12">
                          <p>{fullDescription}</p>
                        </div>
                        <div className="col-md-4 col-xs-12 col-sm-12">
                          <div className="sb-it">
                            <h6>Жанры:</h6>
                            {genres.map((category) => (
                              <p key={category}>
                                <Link
                                  to={`${CATALOG_LINK}${category}`}
                                  tabIndex="0"
                                >
                                  {categories.find((item) => item.id === category).name}
                                </Link>
                              </p>
                            ))}
                          </div>
                          <div className="sb-it">
                            <h6>Год выпуска:</h6>
                            <p>{year}</p>
                          </div>
                          <div className="sb-it">
                            <h6>Продолжительность, мин:</h6>
                            <p>{duration}</p>
                          </div>
                          <div className="sb-it">
                            <h6>Возрастной рейтинг</h6>
                            <p>{ageLimit === '' ? 'Ограничение не установлено' : `${ageLimit}+`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="tab-links tabs-mv">
                    <li className="active">
                      <button
                        type="button"
                      >
                        Похожие фильмы
                      </button>
                    </li>
                  </ul>
                  <VideoSimilar category={genres[0]} key={id} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoDetails;

VideoDetails.propTypes = {
  currentVideo: PropTypes.shape().isRequired,
};
