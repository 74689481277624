import React from 'react';
import PropTypes from 'prop-types';
import {
  convertDateToMonthAndDate,
  generateNewsInfoObjectWithDefaultParams,
} from '../../util';
import NewsTextContainer from '../News-text-container/News-text-container';
import './News-details.css';
import ContentRollContainer from '../Content-roll-container/Content-roll-container';

function NewsDetails({ newsEntry }) {
  const updatedNewsInfo = generateNewsInfoObjectWithDefaultParams(newsEntry);
  const {
    id,
    title,
    trivia,
    image,
    date,
    fullText,
  } = updatedNewsInfo;
  const convertedDate = convertDateToMonthAndDate(date);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="blog-detail-ct">
            <img width="1140" style={{ maxHeight: '440px', width: '100%', objectFit: 'cover' }} src={image} alt={title} />
            <h1>{title}</h1>
            <span className="time">{convertedDate}</span>
            <p className="">{trivia}</p>
            <ContentRollContainer key={id} />
            <NewsTextContainer text={fullText} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsDetails;

NewsDetails.propTypes = {
  newsEntry: PropTypes.shape().isRequired,
};
