export const AppRoute = {
  ALL_CATEGORIES: '/catalog',
  CATALOG: '/catalog/:id',
  ROOT: '/',
  VIDEO: '/video/:id',
  NOT_FOUND: '/404',
  ADMIN: '/admin',
  LOGIN: '/login',
  NEWS: '/news',
  NEWS_ENTRY: '/news/:id',
  ACTORS: '/actors',
  ACTOR: '/actors/:id',
};

export const Links = {
  CATALOG_LINK: '/catalog/',
  VIDEO_LINK: '/video/',
  NEWS_LINK: '/news/',
  INDEX_LINK: '/',
  ACTORS_LINK: '/actors',
  ACTOR_LINK: '/actors/',
  POSTER_LINK: '/assets/img/poster/',
  SLIDER_LINK: '/assets/img/slider/',
  IMAGE_LINK: '/assets/img/movies-img/',
  IMG_FOLDER_LINK: '/assets/img/',
  ACTORS_IMG_LINK: '/assets/img/actors-img/',
  DEFAULT_IAMGE_LINK: '/assets/img/film-placeholder.jpg',
  DEFAULT_POSTER_IMAGE_LINK: '/assets/img/film-poster-placeholder.png',
  BLACK_BG_IMAGE_LINK: '/assets/img/black-bg.jpg',
};

export const OutsideLinks = {
  MOE_VIDEO: 'https://moevideo.com/',
  ADV: 'https://moevideo.com/contact_adv',
  PARTNERSHIP: 'https://moevideo.club/',
  ABOUT: 'https://moevideo.com/company',
  FACEBOOK: 'https://www.facebook.com/',
  TWITTER: 'https://twitter.com/',
  YOUTUBE: 'https://youtu.be/',
  YOUTUBE_EMBED: 'https://www.youtube.com/embed/',
  YOUTUBE_WATCH: 'https://www.youtube.com/watch?v=',
};

// Optimistic value of all categories in the project.
// Change, if DB is getting flooded with categories.
export const ALL_CATEGORIES_COUNT = 1000;
export const RECOMMENDED_VIDEOS_COUNT = 16;
export const FETCHED_SLIDES_COUNT = 10;
export const MAXIMUM_SLIDES_COUNT = 4;
export const INITIAL_CURRENT_CATEGORY = 'new-films';
export const MENU_CATEGORY_SERIALS = 'new-series';
export const HeaderCategories = [
  { id: 'comedy', name: 'Комедии' },
  { id: 'detective', name: 'Детективы' },
  { id: 'action', name: 'Боевики' },
  { id: 'sports', name: 'Спорт' },
];
export const MAIN_SLIDES_ID = [
  'zelenaya-milya',
  'pobeg-iz-shoushenka',
  'igra-prestolov',
  'sherlok',
  'vo-vse-tyazhkie-1',
];

export const CATEGORY_VIDEOS_GRID_COUNT = 24;
export const MAIN_SLIDES_COUNT = 10;
export const MAXIMUM_CARDS_PER_COLUMN = 4;
export const MAXIMUM_NEWS_PER_PAGE = 10;
export const MAXIMUM_NEWS_SLIDES_TO_SHOW = 5;
export const MAXIMUM_NEWS_SLIDES_ON_MAIN = 5;
export const MAXIMUM_CARDS_FOR_RATINGS = 5;
export const MAXIMUM_CAROUSEL_SLIDES = 8;
export const MAXIMUM_CAROUSEL_SLIDES_TO_SHOW = 4;
export const MAIN_ACTORS_COUNT = 5;
export const NEWS_PER_PAGE_COUNT = 10;

export const START_CATEGORIES = [
  { id: 'cartoon', name: 'Мультфильмы' },
  { id: 'drama', name: 'Драмы' },
  { id: 'history', name: 'Исторические фильмы' },
];

export const CategoriesTranslation = {
  'made-in-britain': 'Сделано в Британии',
  'agents-and-spies': 'Агенты и Шпионы',
  'superheroes-and-comics': 'Супергерои и Комиксы',
  'top-250': 'ТОП-250',
  comedy: 'Комедии',
  drama: 'Драмы',
  'action-movie': 'Боевики и Приключения',
  'all-series': 'Все сериалы',
  'new-series': 'Новые Сериалы',
  'new-films': 'Новые Фильмы',
};

export const CategoriesColors = {
  action: 'blue',
  adult: 'yell',
  adventures: 'orange',
  anime: 'green',
  biography: 'blue',
  cartoon: 'yell',
  ceremony: 'orange',
  children: 'green',
  comedy: 'blue',
  concert: 'yell',
  crime: 'orange',
  detective: 'green',
  documentary: 'blue',
  drama: 'yell',
  family: 'orange',
  fantasy: 'green',
  game: 'blue',
  history: 'yell',
  horror: 'orange',
  melodrama: 'green',
  military: 'blue',
  music: 'yell',
  musical: 'orange',
  news: 'green',
  noir: 'blue',
  'real-tv': 'yell',
  'sci-fi': 'orange',
  short: 'green',
  sports: 'blue',
  'talk-show': 'yell',
  thriller: 'orange',
  'western:': 'green',
};

export const CategoriesTranslationRuEn = {
  Комедия: 'comedy',
  Драма: 'drama',
  Детектив: 'agents-and-spies',
  Боевик: 'action-movie',
  Триллер: 'new-films',
  Фантастика: 'superheroes-and-comics',
  Мелодрама: 'drama',
  Фэнтези: 'superheroes-and-comics',
  Мультфильм: 'superheroes-and-comics',
  Приключения: 'action-movie',
  Ужасы: 'new-films',
  История: 'top-250',
  Биография: 'top-250',
  Биографияслова: 'top-250',
  Военный: 'action-movie',
  Криминал: 'agents-and-spies',
  Криминалслова: 'agents-and-spies',
};

export const CurrencyConverter = {
  rub: '\u20BD',
  usd: '\u0024',
};

export const ReleaseCalendarCategories = {
  UPCOMING_RELEASES: 'Скоро в кино',
  DIGITAL_RELEASES: 'Цифровые релизы',
};

export const BoxOfficeCategories = {
  BOX_CIS_RU_CATEGORY: 'Россия и СНГ',
  BOX_WORLD_CATEGORY: 'Мир',
  BOX_NA_CATEGORY: 'США и Канада',
};

export const APIRoute = {
  NEWS: '/news',
  RECENT_NEWS: '/news/recent',
  NEWS_ENTRY: '/news/',
  CATEGORY: '/category',
  CATEGORY_ENTRY: '/category/',
  CATEGORY_VIDEOS_PATH_START: '/category/',
  CATEGORY_VIDEOS_PATH_END: '/videos',
  VIDEO: '/videos',
  VIDEO_ENTRY: '/videos/',
  RANDOM_VIDEO: '/videos/random',
  SIMILAR_VIDEOS: '/videos/similar',
};

export const LoadingStatuses = {
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed',
  INIT: 'init',
};

export const NewsEntryPlaceholder = {
  TITLE: 'Что нового в мире кино',
  TRIVIA: 'Об этом и о многом другом читайте в наших новостях',
  IMAGE: `${Links.DEFAULT_IAMGE_LINK}`,
  FULL_TEXT: '<p>Здесь скоро будет очень интересная новость</p>',
};

export const VideoEntryPlaceholder = {
  POSTER: `${Links.DEFAULT_POSTER_IMAGE_LINK}`,
  SHORT_DESCRIPTION: 'Наша команда пока не добавила описание. Интрига!',
  FULL_DESCRIPTION: 'Описание к фильму пока не добавлено, но пусть это не остановит вас от просмотра.',
};
