import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCategories } from '../../store/selectors';
import {
  Links,
  CategoriesColors,
} from '../../const';
import { generateVideoInfoObjectWithDefaultParams } from '../../util';

function MainSlide({ filmInfo }) {
  const categories = useSelector(getCategories);
  const {
    id,
    title,
    genres,
    poster,
    kpRating,
  } = generateVideoInfoObjectWithDefaultParams(filmInfo);

  const {
    CATALOG_LINK,
    VIDEO_LINK,
  } = Links;

  return (
    <div
      className="movie-item"
    >
      <div className="mv-img" style={{ height: '437px' }}>
        <Link to={`${VIDEO_LINK}${id}`}>
          <img
            src={poster}
            alt={title}
            style={{
              width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center top',
            }}
          />
        </Link>
      </div>
      <div className="title-in">
        <div className="cate">
          {genres.slice(0, 2).map((category) => (
            <span className={CategoriesColors[category]} key={category}>
              <Link
                to={`${CATALOG_LINK}${category}`}
                tabIndex="0"
              >
                {categories.find((item) => item.id === category).name}
              </Link>
            </span>
          ))}
        </div>
        <h6><Link to={`${VIDEO_LINK}${id}`}>{title}</Link></h6>
        <p>
          <i className="ion-android-star" />
          <span>{kpRating}</span>
          {' '}
          /10
        </p>
      </div>
    </div>
  );
}

export default MainSlide;

MainSlide.propTypes = {
  filmInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    enTitle: PropTypes.string.isRequired,
    kpRating: PropTypes.number.isRequired,
    year: PropTypes.string.isRequired,
    ageLimit: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    fullDescription: PropTypes.string.isRequired,
    poster: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
  }).isRequired,
};
