import {
  loadNews,
  loadRecentNews,
  loadNewsEntry,
  setNewsLoadingStatus,
  setRecentNewsLoadingStatus,
  setNewsEntryLoadingStatus,
  setCategoriesLoadingStatus,
  loadCategories,
  setSliderVideosLoadingStatus,
  loadSliderVideos,
  setMainSliderVideosLoadingStatus,
  loadMainSliderVideos,
  setCurrentCategoryLoadingStatus,
  loadCurrentCategory,
  resetCurrentCategoryVideos,
  setCurrentCategoryVideosLoadingStatus,
  loadCurrentCategoryVideos,
  setCurrentVideoLoadingStatus,
  loadCurrentVideo,
} from './action';
import { APIRoute, LoadingStatuses } from '../const';

export const fetchAllCategoriesList = (query) => (dispatch, _getState, api) => {
  dispatch(setCategoriesLoadingStatus(LoadingStatuses.LOADING));
  const {
    start,
    end,
    order,
    sort,
  } = query;
  return api.get(`${APIRoute.CATEGORY}?_end=${end}&_order=${order}&_sort=${sort}&_start=${start}`)
    .then(({ data, headers }) => dispatch(loadCategories({
      data,
      count: headers['x-total-count'],
    })))
    .then(() => dispatch(setCategoriesLoadingStatus(LoadingStatuses.LOADED)))
    .catch(() => dispatch(setCategoriesLoadingStatus(LoadingStatuses.FAILED)));
};

export const fetchNewsList = (query) => (dispatch, _getState, api) => {
  dispatch(setNewsLoadingStatus(LoadingStatuses.LOADING));
  const {
    start,
    end,
    order,
    sort,
    page,
  } = query;
  return api.get(`${APIRoute.NEWS}?_end=${end}&_order=${order}&_sort=${sort}&_start=${start}`)
    .then(({ data, headers }) => dispatch(loadNews({
      data,
      count: headers['x-total-count'],
      page,
    })))
    .then(() => dispatch(setNewsLoadingStatus(LoadingStatuses.LOADED)))
    .catch(() => dispatch(setNewsLoadingStatus(LoadingStatuses.FAILED)));
};

export const fetchRecentNewsList = () => (dispatch, _getState, api) => {
  dispatch(setRecentNewsLoadingStatus(LoadingStatuses.LOADING));

  return api.get(APIRoute.RECENT_NEWS)
    .then(({ data }) => dispatch(loadRecentNews(data)))
    .then(() => dispatch(setRecentNewsLoadingStatus(LoadingStatuses.LOADED)))
    .catch(() => dispatch(setRecentNewsLoadingStatus(LoadingStatuses.FAILED)));
};

export const fetchNewsEntry = (id, cb) => (dispatch, _getState, api) => {
  dispatch(setNewsEntryLoadingStatus(LoadingStatuses.LOADING));

  return api.get(APIRoute.NEWS_ENTRY + id)
    .then(({ data }) => dispatch(loadNewsEntry(data)))
    .then(() => dispatch(setNewsEntryLoadingStatus(LoadingStatuses.LOADED)))
    .catch(() => {
      cb();
      dispatch(setNewsEntryLoadingStatus(LoadingStatuses.FAILED));
    });
};

export const fetchCarouselVideosByCategoryId = (
  categoryId,
  id,
  query,
) => (dispatch, _getState, api) => {
  dispatch(setSliderVideosLoadingStatus(LoadingStatuses.LOADING, id));
  const {
    start,
    end,
    order,
    sort,
  } = query;
  return api.get(`${APIRoute.CATEGORY_VIDEOS_PATH_START}${categoryId}${APIRoute.CATEGORY_VIDEOS_PATH_END}?_end=${end}&_order=${order}&_sort=${sort}&_start=${start}`)
    .then(({ data, headers }) => dispatch(loadSliderVideos({
      data,
      count: headers['x-total-count'],
    }, id)))
    .then(() => dispatch(setSliderVideosLoadingStatus(LoadingStatuses.LOADED, id)))
    .catch(() => dispatch(setSliderVideosLoadingStatus(LoadingStatuses.FAILED, id)));
};

export const fetchVideosByID = (ids) => (dispatch, _getState, api) => {
  dispatch(setMainSliderVideosLoadingStatus(LoadingStatuses.LOADING));
  return api.get(`${APIRoute.VIDEO}?${ids.map((id) => `id=${id}`).join('&')}`)
    .then(({ data }) => dispatch(loadMainSliderVideos({
      data,
    })))
    .then(() => dispatch(setMainSliderVideosLoadingStatus(LoadingStatuses.LOADED)))
    .catch(() => dispatch(setMainSliderVideosLoadingStatus(LoadingStatuses.FAILED)));
};

export const fetchCurrentCategory = (id, cb) => (dispatch, _getState, api) => {
  dispatch(setCurrentCategoryLoadingStatus(LoadingStatuses.LOADING));
  return api.get(`${APIRoute.CATEGORY_VIDEOS_PATH_START}${id}`)
    .then(({ data }) => dispatch(loadCurrentCategory({ data })))
    .then(() => {
      dispatch(resetCurrentCategoryVideos());
      dispatch(setCurrentCategoryLoadingStatus(LoadingStatuses.LOADED));
    })
    .catch(() => {
      cb();
      dispatch(setCurrentCategoryLoadingStatus(LoadingStatuses.FAILED));
    });
};

export const fetchCurrentCategoryVideos = (id, query) => (dispatch, _getState, api) => {
  dispatch(setCurrentCategoryVideosLoadingStatus(LoadingStatuses.LOADING));
  const {
    start,
    end,
    order,
    sort,
    page,
  } = query;
  return api.get(`${APIRoute.CATEGORY_VIDEOS_PATH_START}${id}${APIRoute.CATEGORY_VIDEOS_PATH_END}?_end=${end}&_order=${order}&_sort=${sort}&_start=${start}`)
    .then(({ data, headers }) => dispatch(loadCurrentCategoryVideos({
      data,
      count: headers['x-total-count'],
      page,
    })))
    .then(() => dispatch(setCurrentCategoryVideosLoadingStatus(LoadingStatuses.LOADED)))
    .catch(() => dispatch(setCurrentCategoryVideosLoadingStatus(LoadingStatuses.FAILED)));
};

export const fetchVideo = (id, cb) => (dispatch, _getState, api) => {
  dispatch(setCurrentVideoLoadingStatus(LoadingStatuses.LOADING));
  return api.get(`${APIRoute.VIDEO_ENTRY}${id}`)
    .then(({ data }) => dispatch(loadCurrentVideo({
      data,
    })))
    .then(() => dispatch(setCurrentVideoLoadingStatus(LoadingStatuses.LOADED)))
    .catch(() => {
      cb();
      dispatch(setCurrentVideoLoadingStatus(LoadingStatuses.FAILED));
    });
};
