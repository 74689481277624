import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppRoute, LoadingStatuses } from '../../const';
import TrailerScreen from '../Trailer-screen/Trailer-screen';
import VideoDetails from '../Video-details/Video-details';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import GoToTop from '../Go-to-top/Go-to-top';
import LoadingScreen from '../Loading-screen/Loading-screen';
import { fetchVideo } from '../../store/api-actions';
import { getCurrentVideo, getCurrentVideoLoadingStatus, getCurrentTrailerStatus } from '../../store/selectors';
import { resetCurrentCategoryVideos } from '../../store/action';

function Video() {
  const dispatch = useDispatch();
  const { NOT_FOUND } = AppRoute;
  const { id } = useParams();
  const history = useHistory();
  const currentVideo = useSelector(getCurrentVideo);
  const currentRecipeLoadingStatus = useSelector(getCurrentVideoLoadingStatus);
  const isOpened = useSelector(getCurrentTrailerStatus);

  useEffect(() => {
    if (currentVideo.id !== id) {
      dispatch(resetCurrentCategoryVideos());
      dispatch(fetchVideo(id, () => history.push(NOT_FOUND)));
    }
  }, [NOT_FOUND, currentVideo.id, dispatch, history, id]);

  return (
    <div style={{
      minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#333',
    }}
    >
      <Header />
      {currentRecipeLoadingStatus === LoadingStatuses.LOADING && <LoadingScreen />}
      {currentRecipeLoadingStatus === LoadingStatuses.LOADED && (
        <>
          {isOpened && <TrailerScreen />}
          {<VideoDetails currentVideo={currentVideo} key={currentVideo.id} />}
        </>
      )}
      <Footer />
      <GoToTop />
    </div>
  );
}

export default Video;
