import { useState, useEffect } from 'react';

const useInfiniteScroll = (cb) => {
  const [isFetching, setIsFetching] = useState(false);

  function handleScroll() {
    if (window.pageYOffset >= document.body.scrollHeight - window.innerHeight - 10) {
      setIsFetching(true);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isFetching) {
      cb();
    }
  }, [isFetching, cb]);

  return [isFetching, setIsFetching];
};

export default useInfiniteScroll;
