import React, { useEffect, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector, useDispatch } from 'react-redux';
import {
  getNewsLoadingStatus,
  getNewsCount,
  getNewsPage,
} from '../../store/selectors';
import LoadingScreen from '../Loading-screen/Loading-screen';
import { fetchNewsList } from '../../store/api-actions';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import GoToTop from '../Go-to-top/Go-to-top';
import NewsList from '../News-list/News-list';
import Title from '../Title/Title';
import {
  LoadingStatuses,
  NEWS_PER_PAGE_COUNT,
} from '../../const';
import './News.css';

function News() {
  const categoryTopRef = useRef(null);
  const dispatch = useDispatch();
  const newsLoadingStatus = useSelector(getNewsLoadingStatus);
  const lastFetchedPage = useSelector(getNewsPage);

  useEffect(() => {
    if (newsLoadingStatus === LoadingStatuses.INIT) {
      dispatch(fetchNewsList({
        start: 0,
        end: NEWS_PER_PAGE_COUNT,
        order: 'DESC',
        sort: 'date',
      }));
    }
  }, [dispatch, newsLoadingStatus]);
  const pageCount = Math.ceil(useSelector(getNewsCount) / NEWS_PER_PAGE_COUNT);

  function scrollIntoView() {
    if (categoryTopRef.current) {
      categoryTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const pageChangeHandler = (currentPage) => {
    dispatch(fetchNewsList({
      start: currentPage * NEWS_PER_PAGE_COUNT,
      end: (currentPage + 1) * NEWS_PER_PAGE_COUNT,
      order: 'DESC',
      sort: 'date',
      page: currentPage,
    }));
  };

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <Title currentCategory="Новости" />
      <div
        className="page-single"
        ref={categoryTopRef}
        style={{
          flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'stretch',
        }}
      >
        {newsLoadingStatus === LoadingStatuses.LOADING && <LoadingScreen />}
        {newsLoadingStatus === LoadingStatuses.LOADED && <NewsList />}
        {pageCount > 1 && (
          <div className="pagination">
            <ReactPaginate
              key="list_key"
              onPageChange={(pageNumber) => {
                scrollIntoView();
                pageChangeHandler(pageNumber.selected);
              }}
              containerClassName="pagination-style"
              breakLabel="..."
              pageCount={pageCount}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              initialPage={lastFetchedPage}
              previousLabel={<i className="ion-ios-arrow-left" />}
              nextLabel={<i className="ion-ios-arrow-right" />}
              activeClassName="active"
            />
          </div>
        )}
      </div>
      <Footer />
      <GoToTop />
    </div>
  );
}

export default News;
