import React from 'react';
import { useSelector } from 'react-redux';
import { getActors } from '../../store/selectors';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import GoToTop from '../Go-to-top/Go-to-top';
import Title from '../Title/Title';
import ActorsGrid from '../Actors-grid/Actors-grid';

function Actors() {
  const actors = useSelector(getActors);

  return (
    <div style={{
      minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#333',
    }}
    >
      <Header />
      <Title currentCategory="Звёзды Кино" />
      <ActorsGrid actors={actors} />
      <Footer />
      <GoToTop />
    </div>
  );
}

export default Actors;
