import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getActors,
} from '../../store/selectors';
import { AppRoute } from '../../const';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import GoToTop from '../Go-to-top/Go-to-top';
import Title from '../Title/Title';
import ActorDetails from '../Actor-details/Actor-details';
import { toggleDisplayContentRoll } from '../../store/action';

function Actor() {
  const dispatch = useDispatch();
  const { NOT_FOUND } = AppRoute;
  const { id } = useParams();
  const history = useHistory();
  const actors = useSelector(getActors);

  if (!actors[id]) {
    history.push(NOT_FOUND);
  }
  useEffect(() => {
    dispatch(toggleDisplayContentRoll());
    return () => dispatch(toggleDisplayContentRoll());
  }, [dispatch]);

  return (
    <div style={{
      minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#333',
    }}
    >
      <Header />
      {actors[id] && <Title currentCategory={actors[id].ruName} />}
      {actors[id] && <ActorDetails actor={actors[id]} />}
      <Footer />
      <GoToTop />
    </div>
  );
}

export default Actor;
