/* eslint-disable no-param-reassign */
import { NewsEntryPlaceholder, VideoEntryPlaceholder } from './const';

export const capitalize = (word) => (
  word && word[0].toUpperCase() + word.slice(1).toLowerCase()
);

export const sliceCategoryString = (categoryString) => categoryString.split(', ').map((category) => (
  capitalize(category)
));

export const generateStarRating = (rating, element, emptyElement) => {
  const size = Math.floor(rating);
  const resultArray = new Array(size).fill(element);
  const emptyStarSize = 10 - size;
  const emptyStarArray = new Array(emptyStarSize).fill(emptyElement);

  return [...resultArray, ...emptyStarArray];
};

export const selectRandomVideo = (videos) => {
  const keys = Object.keys(videos).slice(1);
  const keysLength = keys.length - 1;
  const randomIndex = Math.round(Math.random() * keysLength);
  return keys[randomIndex];
};

export const sliceArrayIntoChunks = (array, chunk) => (
  array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []));

export const convertDateToMonthAndDate = (date) => {
  const convertedDate = new Date(date);
  if (convertedDate instanceof Date && !Number.isNaN(convertedDate)) {
    return convertedDate.toLocaleDateString('ru-RU',
      {
        month: 'long',
        day: 'numeric',
      });
  }
  return date;
};

export const getWeekendInterval = () => {
  const currentDate = new Date();
  const weekendStart = new Date().setDate(currentDate.getDate() - (currentDate.getDay() + 2));
  const weekendEnd = new Date().setDate(currentDate.getDate() - currentDate.getDay());

  return `${convertDateToMonthAndDate(weekendStart)} — ${convertDateToMonthAndDate(weekendEnd)}`;
};

export const selectDeclinationPluralFormForNoun = (
  value,
  singleForm,
  pluralFormFromOneToFour,
  pluralForm,
) => {
  if (value === 1) {
    return singleForm;
  }
  return value > 4 ? pluralForm : pluralFormFromOneToFour;
};

export const loadContentRoll = () => {
  const moevideoQueue = window.moevideoQueue || [];
  moevideoQueue.push(() => {
    window.moevideo.ContentRoll({
      mode: 'manual',
      el: '#content-roll-container',
      width: '800px',
      ignorePlayers: true,
      floatMode: 'bottom',
      floatPosition: 'bottom left',
      floatCloseTimeout: 3,
      ref: 'xerov.ru',
    });
  });
};

export const loadScript = () => {
  if (window.moevideo) {
    setTimeout(() => loadContentRoll(), 1000);
  }
};

export const shuffleArray = (array) => {
  const shallowCopy = array.slice();
  const shallowCopyLength = shallowCopy.length;

  for (let i = shallowCopyLength - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [shallowCopy[i], shallowCopy[j]] = [shallowCopy[j], shallowCopy[i]];
  }
  return shallowCopy;
};

export const generateNewsInfoObjectWithDefaultParams = (newsInfo) => {
  const {
    id,
    title,
    trivia,
    image,
    fullText,
    date,
  } = newsInfo;

  return {
    id,
    date,
    title: !(title) ? NewsEntryPlaceholder.TITLE : title,
    trivia: !(trivia) ? NewsEntryPlaceholder.TRIVIA : trivia,
    image: !(image) ? NewsEntryPlaceholder.IMAGE : image,
    fullText: !(fullText) ? NewsEntryPlaceholder.FULL_TEXT : fullText,
  };
};

export const generateVideoInfoObjectWithDefaultParams = (videoInfo) => {
  const {
    poster,
    shortDescription,
    fullDescription,
  } = videoInfo;
  return {
    ...videoInfo,
    poster: !(poster) ? VideoEntryPlaceholder.POSTER : poster,
    shortDescription: !(shortDescription)
      ? VideoEntryPlaceholder.SHORT_DESCRIPTION
      : shortDescription,
    fullDescription: !(fullDescription)
      ? VideoEntryPlaceholder.FULL_DESCRIPTION
      : fullDescription,
  };
};
