import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Links } from '../../const';
import { generateVideoInfoObjectWithDefaultParams } from '../../util';

function CarouselSlide({ filmInfo }) {
  const {
    id,
    title,
    kpRating,
    poster,
  } = generateVideoInfoObjectWithDefaultParams(filmInfo);

  const { VIDEO_LINK } = Links;

  return (
    <div className="slide-it">
      <div className="movie-item">
        <div className="mv-img" style={{ height: '284px' }}>
          <img
            src={poster}
            alt={title}
            width="185"
            height="284"
            style={{
              width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center top',
            }}
          />
        </div>
        <div className="hvr-inner">
          <Link to={`${VIDEO_LINK}${id}`}>
            Подробнее
          </Link>
        </div>
        <div className="title-in">
          <h6><Link to={`${VIDEO_LINK}${id}`}>{title}</Link></h6>
          <p>
            <i className="ion-android-star" />
            <span>{kpRating}</span>
            {' '}
            /10
          </p>
        </div>
      </div>
    </div>
  );
}

export default CarouselSlide;

CarouselSlide.propTypes = {
  filmInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    enTitle: PropTypes.string.isRequired,
    kpRating: PropTypes.number.isRequired,
    year: PropTypes.string.isRequired,
    ageLimit: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    fullDescription: PropTypes.string.isRequired,
    poster: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
  }).isRequired,
};
