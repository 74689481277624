/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCurrentTrailer } from '../../store/action';
import { getCurrentTrailerUrl } from '../../store/selectors';

function TrailerScreen() {
  const dispatch = useDispatch();
  useEffect(() => {
    function handleKeyDown() {
      dispatch(resetCurrentTrailer());
    }

    document.addEventListener('keydown', handleKeyDown);

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [dispatch]);

  const trailerUrl = useSelector(getCurrentTrailerUrl);
  return (
    <>
      <div
        className="fancybox-overlay fancybox-overlay-fixed"
        style={{
          width: 'auto', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <div
          className="fancybox-wrap fancybox-desktop fancybox-type-iframe fancybox-opened"
          tabIndex="-1"
          style={{
            width: '50%', height: '50vh', opacity: '1', overflow: 'visible', position: 'relative',
          }}
        >
          <div className="fancybox-skin" style={{ padding: '15px', width: '100%', height: '100%' }}>
            <div className="fancybox-outer" style={{ width: '100%', height: '100%' }}>
              <div className="fancybox-inner" style={{ overflow: 'auto', width: '100%', height: '100%' }}>
                <iframe
                  title="Trailer Player"
                  id="fancybox-frame1632236126826"
                  name="fancybox-frame1632236126826"
                  className="fancybox-iframe"
                  frameBorder="0"
                  vspace="0"
                  hspace="0"
                  webkitallowfullscreen=""
                  mozallowfullscreen=""
                  allowFullScreen=""
                  scrolling="auto"
                  src={trailerUrl}
                />
              </div>
            </div>
            <button
              type="button"
              title="Close"
              className="fancybox-item fancybox-close"
              onClick={() => {
                dispatch(resetCurrentTrailer());
              }}
              onKeyDown={() => {
                dispatch(resetCurrentTrailer());
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default TrailerScreen;
