import React from 'react';
import PropTypes from 'prop-types';
import { generateVideoInfoObjectWithDefaultParams } from '../../util';

function TrailerText({ filmInfo }) {
  const {
    title,
    duration,
    poster,
  } = generateVideoInfoObjectWithDefaultParams(filmInfo);

  return (
    <div className="item" style={{ display: 'flex' }}>
      <div className="trailer-img">
        <img
          src={poster}
          alt={title}
          style={{ objectFit: 'cover', objectPosition: 'center' }}
        />
      </div>
      <div className="trailer-infor">
        <h4 className="desc">{title}</h4>
        <p>{`Продолжительность, мин: ${duration}`}</p>
      </div>
    </div>
  );
}

export default TrailerText;

TrailerText.propTypes = {
  filmInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    enTitle: PropTypes.string.isRequired,
    kpRating: PropTypes.number.isRequired,
    year: PropTypes.string.isRequired,
    ageLimit: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    fullDescription: PropTypes.string.isRequired,
    poster: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
  }).isRequired,
};
