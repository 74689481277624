import React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import './News-text-container.css';

function NewsTextContainer({ text }) {
  return (
    <>
      {parse(text)}
    </>
  );
}

export default NewsTextContainer;

NewsTextContainer.propTypes = {
  text: PropTypes.string.isRequired,
};
