import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Links,
} from '../../const';
import { capitalize } from '../../util';

function Title({ currentCategory }) {
  const { INDEX_LINK } = Links;

  return (
    <div className="hero common-hero">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="hero-ct">
              <h1>{capitalize(currentCategory)}</h1>
              <ul className="breadcumb">
                <li className="active"><Link to={INDEX_LINK}>Главная</Link></li>
                <li>
                  {' '}
                  <span className="ion-ios-arrow-right" />
                  {' '}
                  {capitalize(currentCategory)}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Title;

Title.propTypes = {
  currentCategory: PropTypes.string.isRequired,
};
