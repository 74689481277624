import React from 'react';
import { useSelector } from 'react-redux';
import NewsCard from '../News-card/News-card';
import { getNews } from '../../store/selectors';

function NewsList() {
  const news = useSelector(getNews).slice();

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          {news.map((newsEntry) => <NewsCard newsEntry={newsEntry} key={newsEntry.id} />)}
        </div>
      </div>
    </div>
  );
}

export default NewsList;
