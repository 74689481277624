import React from 'react';
import PropTypes from 'prop-types';
import { OutsideLinks } from '../../const';

function TrailerSlide({ trailer }) {
  const { YOUTUBE_EMBED } = OutsideLinks;
  const trailerEmbed = trailer.length !== 0 ? trailer[0].link.split('?v=')[1] : 'eRkvJ71O2yc';
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <iframe className="item-video" title="trailerUrl" src={`${YOUTUBE_EMBED}${trailerEmbed}?autoplay=1&mute=1`} key={trailerEmbed} style={{ width: '100%', height: '100%' }} />
    </div>
  );
}

export default TrailerSlide;

TrailerSlide.propTypes = {
  trailer: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
