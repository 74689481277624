/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Links,
  RECOMMENDED_VIDEOS_COUNT,
} from '../../const';
import { shuffleArray } from '../../util';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import ContentRollContainer from '../Content-roll-container/Content-roll-container';

function ActorDetails({ actor, videos }) {
  const [content, setContent] = useState({
    allVideos: Object.keys(videos),
    movies: Object.keys(videos).slice(0, RECOMMENDED_VIDEOS_COUNT),
    page: 0,
  });
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);
  function fetchMoreListItems() {
    if ((content.page + 2) * RECOMMENDED_VIDEOS_COUNT <= content.allVideos.length) {
      setContent((prevState) => ({
        ...prevState,
        movies: [
          ...prevState.movies,
          ...prevState.allVideos.slice(
            (prevState.page + 1) * RECOMMENDED_VIDEOS_COUNT,
            (prevState.page + 2) * RECOMMENDED_VIDEOS_COUNT,
          )],
        page: prevState.page + 1,
      }));
    } else {
      setContent((prevState) => ({
        allVideos: shuffleArray(prevState.allVideos),
        movies: [
          ...prevState.movies,
          ...prevState.allVideos.slice(
            0,
            RECOMMENDED_VIDEOS_COUNT,
          )],
        page: 0,
      }));
    }
    setIsFetching(false);
  }

  const {
    actorId,
    name,
    ruName,
    birthday,
    career,
    bio,
    filmography,
    'personal-life': personalLife,
    facts,
  } = actor;
  const age = Number(new Date().getFullYear()) - Number(birthday.split(' ')[2]);
  const {
    POSTER_LINK,
    ACTORS_IMG_LINK,
    VIDEO_LINK,
    ACTORS_LINK,
  } = Links;

  const [offset, setOffset] = useState(0);
  const checkScrollTop = () => {
    if (window.innerWidth > 990) {
      setOffset(window.pageYOffset);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return function cleanup() {
      window.removeEventListener('scroll', checkScrollTop);
    };
  });

  return (
    <div
      className="page-single movie-single movie_single"
      style={{
        flex: '1', marginTop: '0', paddingTop: '100px',
      }}
    >
      <div className="container">
        <div className="row ipad-width2">
          <div className="col-md-4 col-sm-12 col-xs-12">
            <div className="movie-img sticky-sb" style={{ top: offset }}>
              <img
                src={`${ACTORS_IMG_LINK}${actorId}.jpg`}
                alt={ruName}
                style={{
                  height: '490px', width: 'auto', maxHeight: '490px', maxWidth: '340px', objectFit: 'cover', objectPosition: 'center top',
                }}
              />
              <div className="movie-btn">
                <div className="btn-transform transform-vertical red">
                  <div>
                    <Link
                      to={ACTORS_LINK}
                      className="item item-1 redbtn"
                    >
                      <i className="ion-ios-film" />
                      Другие актеры
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={ACTORS_LINK}
                      className="item item-2 redbtn fancybox-media hvr-grow"
                    >
                      <i style={{ fontSize: '16px' }} className="ion-ios-arrow-forward" />
                      Анкор!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-sm-12 col-xs-12">
            <div className="movie-single-ct main-content">
              <h1 className="bd-hd">
                {`${ruName}  `}
                <span>{name}</span>
              </h1>
              <div className="movie-rate" style={{ marginBottom: '25px' }}>
                <div className="rate">
                  <p>
                    <span>{`${birthday} / возраст: ${age} / ${career}`}</span>
                  </p>
                </div>
              </div>
              <div className="movie-tabs">
                <div className="tabs">
                  <ul className="tab-links tabs-mv">
                    <li className="active">
                      <button
                        type="button"
                      >
                        Биография
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div id="overview" className="tab active">
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <p>{bio}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="tab-links tabs-mv">
                    <li className="active">
                      <button
                        type="button"
                      >
                        Фильмография
                      </button>
                    </li>
                  </ul>
                  <ContentRollContainer key={actorId} />
                  <div className="tab-content">
                    <div id="overview" className="tab active">
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <p>{filmography.heading}</p>
                          <ul>
                            {filmography.films.map((film) => (
                              <li style={{ paddingBottom: '10px' }} key={film.name}>
                                <h3>{film.name}</h3>
                                <p>{film.info}</p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="tab-links tabs-mv">
                    <li className="active">
                      <button
                        type="button"
                      >
                        Личная жизнь
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div id="overview" className="tab active">
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <p>{personalLife}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="tab-links tabs-mv">
                    <li className="active">
                      <button
                        type="button"
                      >
                        Интересные факты
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div id="overview" className="tab active">
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <ul>
                            {facts.map((fact, index) => (
                              <li style={{ paddingBottom: '10px' }} key={index}>
                                <p>
                                  <i className="ion-android-star" style={{ color: '#f5b50a' }} />
                                  {' '}
                                  {fact}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="tab-links tabs-mv">
                    <li className="active">
                      <button
                        type="button"
                      >
                        Рекоменудем посмотреть
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div id="moviesrelated" className="tab active">
                      <div className="row" style={{ paddingLeft: '20px' }}>
                        {content.movies.map((similarFilmId) => {
                          const {
                            title: similarFilmTitle,
                            year: similarFilmYear,
                            rating: similarFilmRating,
                            text,
                            time: similarFilmTime,
                            age: similarFilmAge,
                          } = videos[similarFilmId];
                          return (
                            <div className="movie-item-style-2" key={similarFilmTitle}>
                              <img src={`${POSTER_LINK}${similarFilmId}.jpg`} alt={similarFilmTitle} style={{ maxWidth: '300px' }} />
                              <div className="mv-item-infor">
                                <h6>
                                  <Link to={`${VIDEO_LINK}${similarFilmId}`}>
                                    {similarFilmTitle}
                                    <span>{` (${similarFilmYear})`}</span>
                                  </Link>
                                </h6>
                                <p className="rate">
                                  <i className="ion-android-star" />
                                  <span>{similarFilmRating}</span>
                                  {' '}
                                  /10
                                </p>
                                <p className="describe">{`${text}...`}</p>
                                <p className="run-time">
                                  {`Продолжительность: ${similarFilmTime}`}
                                  <span>{`Рейтинг: ${similarFilmAge}+`}</span>
                                </p>
                              </div>
                            </div>
                          );
                        })}
                        {isFetching && <p>Загружаем видео</p>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ActorDetails.propTypes = {
  actor: PropTypes.shape().isRequired,
  videos: PropTypes.shape().isRequired,
};

export default ActorDetails;
