import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect, Route } from 'react-router-dom';
import NotFound from '../Not-found/Not-found';
import Home from '../Home/Home';
import Category from '../Category/Category';
import News from '../News/News';
import NewsEntry from '../News-entry/News-entry';
import Video from '../Video/Video';
import ContactUs from '../Contact-us/Contact-us';
import ScrollToTop from '../Scroll-to-top/Scroll-to-top';
import { AppRoute, LoadingStatuses } from '../../const';
import { getCategoriesLoadingStatus, getOpenedContactFormStatus } from '../../store/selectors';
import LoadingScreen from '../Loading-screen/Loading-screen';
import Actors from '../Actors/Actors';
import Actor from '../Actor/Actor';

function App() {
  const {
    ROOT,
    NOT_FOUND,
    CATALOG,
    VIDEO,
    NEWS,
    NEWS_ENTRY,
    ACTORS,
    ACTOR,
  } = AppRoute;
  const isContactFormOpened = useSelector(getOpenedContactFormStatus);
  const categoriesLoadingStatus = useSelector(getCategoriesLoadingStatus);

  if (categoriesLoadingStatus !== LoadingStatuses.LOADED) return <LoadingScreen />;

  return (
    <ScrollToTop>
      <Switch>
        <Route exact path={ROOT}>
          <Home />
        </Route>
        <Route exact path={CATALOG}>
          <Category />
        </Route>
        <Route exact path={VIDEO}>
          <Video />
        </Route>
        <Route exact path={NEWS}>
          <News />
        </Route>
        <Route exact path={NEWS_ENTRY}>
          <NewsEntry />
        </Route>
        <Route exact path={ACTORS}>
          <Actors />
        </Route>
        <Route exact path={ACTOR}>
          <Actor />
        </Route>
        <Route path={NOT_FOUND}>
          <NotFound />
        </Route>
        <Redirect to={NOT_FOUND} />
      </Switch>
      {isContactFormOpened && <ContactUs />}
    </ScrollToTop>
  );
}

export default App;
