import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CarouselSlide from '../Carousel-slide/Carousel-slide';
import {
  MAXIMUM_CAROUSEL_SLIDES_TO_SHOW,
  Links,
} from '../../const';

function Carousel({ category, slides }) {
  const { CATALOG_LINK } = Links;

  return (
    <>
      <div className="title-hd">
        <h2 className="main-title">{category.name}</h2>
        <Link
          to={`${CATALOG_LINK}${category.id}`}
          className="viewall"
          tabIndex="0"
        >
          Посмотреть все фильмы в категории
          <i className="ion-ios-arrow-right" />
        </Link>
      </div>
      <div className="tabs">
        <div className="tab-content">
          <div id="tab1" className="tab active">
            <div className="row">
              <Slider
                className="slick-multiItem"
                dots
                arrows={false}
                infinite
                slidesToShow={MAXIMUM_CAROUSEL_SLIDES_TO_SHOW}
                slidesToScroll={MAXIMUM_CAROUSEL_SLIDES_TO_SHOW}
                responsive={[
                  {
                    breakpoint: 1215,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                    },
                  },
                  {
                    breakpoint: 1007,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    },
                  },
                ]}
              >
                {slides.map((filmInfo) => (
                  <CarouselSlide
                    filmInfo={filmInfo}
                    key={filmInfo.id}
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Carousel;

Carousel.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape).isRequired,
  category: PropTypes.shape().isRequired,
};
