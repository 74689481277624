import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Carousel from '../Carousel/Carousel';
import MainSlider from '../Main-slider/Main-slider';
import {
  START_CATEGORIES,
  LoadingStatuses,
  FETCHED_SLIDES_COUNT,
} from '../../const';
import {
  getSliderVideos,
} from '../../store/selectors';
import Trailer from '../Trailer/Trailer';
import NewsGrid from '../News-grid/News-grid';
import MainSidebar from '../Main-sidebar/Main-sidebar';
import LoadingScreen from '../Loading-screen/Loading-screen';
import { fetchCarouselVideosByCategoryId } from '../../store/api-actions';

function Main() {
  const dispatch = useDispatch();
  const sliderVideos = useSelector(getSliderVideos);

  const [first, second] = START_CATEGORIES;
  const [firstCarousel, secondCarousel, thirdCarousel] = sliderVideos;

  useEffect(() => {
    sliderVideos.forEach((carousel, index) => {
      if (carousel.loadingStatus === LoadingStatuses.INIT) {
        dispatch(fetchCarouselVideosByCategoryId(START_CATEGORIES[index].id, index, {
          start: 0,
          end: index === 0
            ? FETCHED_SLIDES_COUNT
            : FETCHED_SLIDES_COUNT,
          order: 'DESC',
          sort: 'kpVotes',
        }));
      }
    });
  }, [dispatch, sliderVideos]);

  return (
    <>
      <MainSlider />
      <div className="movie-items">
        <div className="container">
          <div className="row ipad-width">
            <div className="col-md-8">
              {firstCarousel.loadingStatus === LoadingStatuses.LOADING && <LoadingScreen />}
              {firstCarousel.loadingStatus === LoadingStatuses.LOADED && (
                <Carousel
                  category={first}
                  slides={firstCarousel.data}
                />
              )}
              {secondCarousel.loadingStatus === LoadingStatuses.LOADING && <LoadingScreen />}
              {secondCarousel.loadingStatus === LoadingStatuses.LOADED && (
                <Carousel
                  category={second}
                  slides={secondCarousel.data}
                />
              )}
            </div>
            <div className="col-md-4">
              <MainSidebar />
            </div>
          </div>
        </div>
      </div>
      {thirdCarousel.loadingStatus === LoadingStatuses.LOADING && <LoadingScreen />}
      {thirdCarousel.loadingStatus === LoadingStatuses.LOADED && (
        <Trailer
          slides={thirdCarousel.data}
        />
      )}
      <NewsGrid />
    </>
  );
}

export default Main;
