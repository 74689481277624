import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Links } from '../../const';
import {
  convertDateToMonthAndDate,
  generateNewsInfoObjectWithDefaultParams,
} from '../../util';

function NewsCard({ newsEntry }) {
  const updatedNewsInfo = generateNewsInfoObjectWithDefaultParams(newsEntry);
  const {
    id,
    title,
    trivia,
    image,
    date,
  } = updatedNewsInfo;
  const convertedDate = convertDateToMonthAndDate(date);
  const { NEWS_LINK } = Links;

  return (
    <div className="blog-item-style-1 blog-item-style-3">
      <Link to={`${NEWS_LINK}${id}`}>
        <img
          src={image}
          alt={title}
          style={{ maxHeight: '270px', width: '480px', objectFit: 'cover' }}
        />
      </Link>
      <div className="blog-it-infor">
        <h3><Link to={`${NEWS_LINK}${id}`}>{title}</Link></h3>
        <span className="time">{convertedDate}</span>
        <p>{`${trivia}...`}</p>
      </div>
    </div>
  );
}

export default NewsCard;

NewsCard.propTypes = {
  newsEntry: PropTypes.shape().isRequired,
};
