import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Links, MAIN_ACTORS_COUNT } from '../../const';
import { getActors } from '../../store/selectors';
import { shuffleArray } from '../../util';

function MainSidebar() {
  const actors = useSelector(getActors);
  const randomActors = shuffleArray(Object.keys(actors)).slice(0, MAIN_ACTORS_COUNT);
  const { ACTORS_IMG_LINK, ACTOR_LINK, ACTORS_LINK } = Links;
  return (
    <div className="sidebar">
      <div className="celebrities">
        <h4 className="sb-title">Звёзды кино</h4>
        {randomActors.map((actor) => {
          const { actorId, ruName, career } = actors[actor];
          return (
            <div className="celeb-item" key={actorId}>
              <Link to={`${ACTOR_LINK}${actorId}`}>
                <img
                  src={`${ACTORS_IMG_LINK}${actorId}.jpg`}
                  alt={ruName}
                  style={{
                    maxHeight: '70px', maxWidth: '70px', objectFit: 'cover', objectPosition: 'center top',
                  }}
                  width="70"
                  height="70"
                />
              </Link>
              <div className="celeb-author">
                <h6>
                  <Link to={`${ACTOR_LINK}${actorId}`}>{ruName}</Link>
                </h6>
                <span>{career}</span>
              </div>
            </div>
          );
        })}
        <Link to={ACTORS_LINK} className="btn">
          Больше звёзд кино
          <i className="ion-ios-arrow-right" />
        </Link>
      </div>
    </div>
  );
}

export default MainSidebar;
