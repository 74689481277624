import React from 'react';
import PropTypes from 'prop-types';
import {
  loadScript,
} from '../../util';

function ContentRollContainer({ style }) {
  setTimeout(() => loadScript(), 1000);
  return (<div id="content-roll-container" style={style} />);
}

export default ContentRollContainer;

ContentRollContainer.propTypes = {
  style: PropTypes.shape(),
};

ContentRollContainer.defaultProps = {
  style: {},
};
