import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';
import App from './components/App/App';
import reducer from './store/reducer';
import {
  loadActors,
} from './store/action';
import actors from './data/actors.json';
import createAPI from './services/api';
import {
  fetchAllCategoriesList,
} from './store/api-actions';
import { ALL_CATEGORIES_COUNT } from './const';

const api = createAPI(
  () => {},
  () => {},
);

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: {
      extraArgument: api,
    },
  }),
});

store.dispatch(loadActors(actors));
store.dispatch(fetchAllCategoriesList({
  start: 0,
  end: ALL_CATEGORIES_COUNT,
  order: 'DESC',
  sort: 'id',
}));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
