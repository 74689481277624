import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toggleDisplayForm } from '../../store/action';
import { Links } from '../../const';
import './Footer.css';

function Footer() {
  const dispatch = useDispatch();
  const {
    INDEX_LINK,
    IMG_FOLDER_LINK,
  } = Links;
  return (
    <footer className="ht-footer">
      <div className="container p centered">
        <Link to={INDEX_LINK}><img className="logo" src={`${IMG_FOLDER_LINK}logo.svg`} alt="moevideo.biz" /></Link>
      </div>
      <div className="ft-copyright">
        <div className="container centered">
          <p>
            © 2010-2021
            {' '}
            <button
              title="Свяжитесь с нами"
              type="button"
              className="contact-us"
              onClick={() => dispatch(toggleDisplayForm())}
            >
              MoeVideo.biz
            </button>
          </p>
        </div>
      </div>
    </footer>

  );
}

export default Footer;
