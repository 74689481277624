/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import {
  Links,
  HeaderCategories,
} from '../../const';
import { getCategories } from '../../store/selectors';

function Header() {
  const [isOpened, setIsOpened] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const {
    CATALOG_LINK,
    INDEX_LINK,
    IMG_FOLDER_LINK,
    NEWS_LINK,
    ACTORS_LINK,
  } = Links;
  const categories = useSelector(getCategories);

  const handleEnter = () => {
    setOpenMenu(true);
  };

  const handleLeave = () => {
    setOpenMenu(false);
  };

  const handleClick = () => {
    setOpenMenu(false);
  };

  useEffect(() => {
    function handleButtonClick() {
      if (isOpened) {
        setIsOpened(!isOpened);
      }
    }

    document.addEventListener('click', handleButtonClick);

    return function cleanup() {
      document.removeEventListener('click', handleButtonClick);
    };
  }, [isOpened]);
  return (
    <header className="ht-header sticky">
      <div className="container">
        <nav className="navbar navbar-default navbar-custom">
          <div className="navbar-header logo">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              onClick={() => setIsOpened(true)}
            >
              <span className="sr-only">Toggle navigation</span>
              <div id="nav-icon1">
                <span />
                <span />
                <span />
              </div>
            </button>
            <Link to={INDEX_LINK}><img className="logo" src={`${IMG_FOLDER_LINK}logo-horizontal.svg`} alt="" width="119" height="58" /></Link>
          </div>

          <div className={isOpened ? 'open navbar-collapse flex-parent' : 'collapse navbar-collapse flex-parent'} id="bs-example-navbar-collapse-1">
            <ul className="nav navbar-nav flex-child-menu menu-left">
              <li className="hidden">
                <a href="#page-top" />
              </li>
              <li className="dropdown first">
                <NavLink className="btn btn-default dropdown-toggle lv1" data-toggle="dropdown" to={INDEX_LINK}>
                  Главная
                </NavLink>
              </li>
              <li
                className={openMenu ? 'dropdown first open' : 'dropdown first'}
                onMouseEnter={handleEnter}
                onMouseLeave={handleLeave}
                onFocus={handleEnter}
              >
                <p className="btn btn-default dropdown-toggle lv1" data-toggle="dropdown" data-hover="dropdown">
                  Жанры
                  <i className="fa fa-angle-down" aria-hidden="true" />
                </p>
                <ul className="dropdown-menu level1">
                  {categories.slice(0, 15).map((category) => (
                    <li className="dropdown" key={category.id}>
                      <Link
                        to={`${CATALOG_LINK}${category.id}`}
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        onClick={() => {
                          handleClick();
                        }}
                      >
                        {category.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              {HeaderCategories.map((category) => (
                <li className="dropdown first" key={category.name}>
                  <Link to={`${CATALOG_LINK}${category.id}`} className="btn btn-default dropdown-toggle lv1" data-toggle="dropdown" data-hover="dropdown">
                    {category.name}
                  </Link>
                </li>
              ))}
              <li className="dropdown first">
                <Link to={NEWS_LINK} className="btn btn-default dropdown-toggle lv1" data-toggle="dropdown" data-hover="dropdown">
                  Новости
                </Link>
              </li>
              <li className="dropdown first">
                <Link to={ACTORS_LINK} className="btn btn-default dropdown-toggle lv1" data-toggle="dropdown" data-hover="dropdown">
                  Звёзды кино
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
