import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toggleDisplayForm } from '../../store/action';
import './Contact-us.css';

function ContactUs() {
  const dispatch = useDispatch();
  useEffect(() => {
    function handleKeyDown(evt) {
      if (evt.keyCode === 27) {
        dispatch(toggleDisplayForm());
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [dispatch]);

  return (
    <div className="overlay openform">
      <div className="login-wrapper">
        <button
          title="Close (Esc)"
          type="button"
          className="contact-us-close"
          onClick={() => {
            dispatch(toggleDisplayForm());
          }}
          onKeyDown={() => {
            dispatch(toggleDisplayForm());
          }}
        >
          ×
        </button>
        <div
          className="login-content"
        >
          <div
            className="text-center iq-title-box iq-box iq-title-default"
          >
            <h3 className="iq-title">
              Свяжитесь с нами
            </h3>
            <p className="iq-title-desc">Заполните форму обратной связи, чтобы связаться с нашей великолепной командой MoeVideo </p>
          </div>
          <form action="#" method="post" className="wpcf7-form init" autoComplete="off">
            <div className="row">
              <label htmlFor="name">
                Имя:
                <input type="text" name="name" id="name" placeholder="Ваше имя" required="required" />
              </label>
            </div>
            <div className="row">
              <label htmlFor="surname">
                Фамилия:
                <input type="text" name="surname" id="surname" placeholder="Ваша фамилия" required="required" />
              </label>
            </div>
            <div className="row">
              <label htmlFor="telephone">
                Телефон:
                <input type="tel" name="telephone" id="telephone" placeholder="" required="required" />
              </label>
            </div>
            <div className="row">
              <label htmlFor="email">
                E-mail:
                <input type="email" name="email" id="email" placeholder="" required="required" />
              </label>
            </div>
            <div className="row">
              <label htmlFor="message">
                Ваше сообщение
                <textarea
                  name="message"
                  cols="40"
                  rows="10"
                  required
                  className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                  aria-required="true"
                  aria-invalid="false"
                  placeholder="Укажите ваше сообщение тут"
                />
              </label>
            </div>
            <div className="row">
              <button type="submit">Отправить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
