/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getRecentNews,
  getRecentNewsLoadingStatus,
} from '../../store/selectors';
import {
  MAXIMUM_NEWS_SLIDES_TO_SHOW,
  Links,
  LoadingStatuses,
} from '../../const';
import LoadingScreen from '../Loading-screen/Loading-screen';
import { fetchRecentNewsList } from '../../store/api-actions';
import { convertDateToMonthAndDate, generateNewsInfoObjectWithDefaultParams } from '../../util';

function NewsGrid() {
  const dispatch = useDispatch();
  const recentNewsLoadingStatus = useSelector(getRecentNewsLoadingStatus);

  useEffect(() => {
    if (recentNewsLoadingStatus === LoadingStatuses.INIT) {
      dispatch(fetchRecentNewsList());
    }
  }, [dispatch, recentNewsLoadingStatus]);

  const news = useSelector(getRecentNews);
  const { NEWS_LINK } = Links;
  const slidesToShow = news.length < MAXIMUM_NEWS_SLIDES_TO_SHOW
    ? news.length
    : MAXIMUM_NEWS_SLIDES_TO_SHOW;
  let headline = {};
  if (news[0]) {
    headline = generateNewsInfoObjectWithDefaultParams(news[0]);
  }

  const regularNews = news.slice(1, slidesToShow);

  return (
    <div className="latestnew">
      <div className="container">
        <div className="row ipad-width">
          <div className="col-md-12">
            <div className="title-hd">
              <h2>Новости</h2>
            </div>
            {recentNewsLoadingStatus === LoadingStatuses.LOADING && <LoadingScreen />}
            {recentNewsLoadingStatus === LoadingStatuses.LOADED && (
              <>
                <div className="tabs" style={{ marginLeft: '0' }}>
                  <div className="tab-content">
                    <div id="tab31" className="tab active">
                      <div className="blog-it-infor" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Link to={`${NEWS_LINK}${headline.id}`}>
                          <img
                            src={headline.image}
                            alt=""
                            width="170"
                            height="250"
                            style={{
                              width: '100%', maxHeight: '300px', objectFit: 'cover', paddingBottom: '20px', objectPosition: '0 20%',
                            }}
                          />
                        </Link>
                        <div style={{ flexGrow: '1' }}>
                          <h3><Link to={`${NEWS_LINK}${headline.id}`}>{headline.title}</Link></h3>
                          <span className="time">{convertDateToMonthAndDate(headline.date)}</span>
                          <p>{headline.trivia}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="morenew">
                  <div className="title-hd">
                    <h3>Что нового на moevideo.biz</h3>
                    <Link to={NEWS_LINK} className="viewall">
                      Посмотреть все новости
                      <i className="ion-ios-arrow-right" />
                    </Link>
                  </div>
                  <div className="more-items row ipad-width" style={{ flexWrap: 'wrap' }}>
                    {regularNews.map((newsEntry) => {
                      const updatedNewsInfo = generateNewsInfoObjectWithDefaultParams(newsEntry);
                      const {
                        id,
                        title,
                        date,
                        image,
                      } = updatedNewsInfo;

                      return (
                        <div className="more-it col-md-4" style={{ marginRight: '0' }} key={id}>
                          <Link to={`${NEWS_LINK}${id}`}>
                            <img
                              src={image}
                              alt=""
                              width="170"
                              height="250"
                              style={{
                                width: '100%', paddingBottom: '10px', minHeight: '150px', maxHeight: '200px', objectFit: 'cover',
                              }}
                            />
                          </Link>
                          <h6><Link to={`${NEWS_LINK}${id}`}>{title}</Link></h6>
                          <span className="time">{convertDateToMonthAndDate(date)}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsGrid;
