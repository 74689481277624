import React from 'react';
import './Loading-screen.css';

function LoadingScreen() {
  return (
    <div className="cssloader">
      <div className="sh1" />
      <div className="sh2" />
    </div>
  );
}

export default LoadingScreen;
