import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentCategory, getCurrentCategoryLoadingStatus } from '../../store/selectors';
import { AppRoute, LoadingStatuses } from '../../const';
import MainGrid from '../Main-grid/Main-grid';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import GoToTop from '../Go-to-top/Go-to-top';
import Title from '../Title/Title';
import LoadingScreen from '../Loading-screen/Loading-screen';
import { fetchCurrentCategory } from '../../store/api-actions';

function Category() {
  const { NOT_FOUND } = AppRoute;
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const currentCategory = useSelector(getCurrentCategory);
  const currentCategoryLoadingStatus = useSelector(getCurrentCategoryLoadingStatus);

  useEffect(() => {
    if (currentCategory.id !== id) {
      dispatch(fetchCurrentCategory(id, () => history.push(NOT_FOUND)));
    }
  }, [NOT_FOUND, currentCategory, dispatch, history, id]);

  return (
    <div style={{
      minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#333',
    }}
    >
      <Header />
      {currentCategoryLoadingStatus === LoadingStatuses.LOADING && <LoadingScreen />}
      {currentCategoryLoadingStatus === LoadingStatuses.LOADED && (
        <>
          <Title currentCategory={currentCategory.name} />
          <MainGrid />
        </>
      )}
      <Footer />
      <GoToTop />
    </div>
  );
}

export default Category;
