import React from 'react';
import { Link } from 'react-router-dom';
import { AppRoute } from '../../const';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import GoToTop from '../Go-to-top/Go-to-top';
import './Not-found.css';

function NotFound() {
  const { ROOT } = AppRoute;

  return (
    <div style={{
      minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#333',
    }}
    >
      <Header />
      <main
        id="content"
        style={{
          flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
      >
        <div className="container">
          <div className="space-1 space-md-2 text-center">
            <div className="max-width-875rem mx-auto pt-md-4 pb-md-5 mb-md-1 middle-content">
              <h1>404!</h1>
              <h2 className="">Очень жаль, по данному адресу мы не смогли ничего найти.</h2>
              <div>
                <Link
                  to={ROOT}
                  className="redbtn"
                >
                  НА ГЛАВНУЮ
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <GoToTop />
    </div>
  );
}

export default NotFound;
