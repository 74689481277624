import { createAction } from '@reduxjs/toolkit';

export const ActionType = {
  LOAD_CATEGORIES: 'categories/loadCategories',
  SET_CATEGORIES_LOADING_STATUS: 'categories/setCategoriesLoadingStatus',
  LOAD_CURRENT_CATEGORY: 'categories/loadCurrentCategory',
  SET_CURRENT_CATEGORY_LOADING_STATUS: 'categories/setCurrentCategoryLoadingStatus',
  LOAD_CURRENT_CATEGORY_VIDEOS: 'videos/loadCurrentCategoryVideos',
  SET_CURRENT_CATEGORY_VIDEOS_LOADING_STATUS: 'videos/setCurrentCategoryVideosLoadingStatus',
  RESET_CURRENT_CATEGORY_VIDEOS: 'recipes/resetCurrentCategoryVideos',
  LOAD_CURRENT_VIDEO: 'videos/loadCurrentVideo',
  SET_CURRENT_VIDEO_LOADING_STATUS: 'videos/setCurrentViedoLoadingStatus',
  LOAD_DIGITAL_RELEASES: 'releases/loadDigitalReleases',
  LOAD_SOON_RELEASES: 'releases/loadSoonReleases',
  LOAD_BOX_OFFICE: 'releases/loadBoxOffice',
  LOAD_NEWS: 'news/loadNews',
  SET_NEWS_LOADING_STATUS: 'news/setNewsLoadingStatus',
  LOAD_RECENT_NEWS: 'news/loadRecentNews',
  SET_RECENT_NEWS_LOADING_STATUS: 'news/setRecentNewsLoadingStatus',
  LOAD_NEWS_ENTRY: 'news/loadNewsEntry',
  SET_NEWS_ENTRY_LOADING_STATUS: 'news/setNewsEntryLoadingStatus',
  LOAD_ACTORS: 'actors/loadActors',
  SET_CURRENT_CATEGORY: 'categories/setCurrentCategory',
  SET_CURRENT_TRAILER: 'trailer/setCurrentTrailer',
  RESET_CURRENT_TRAILER: 'trailer/resetCurrentTrailer',
  TOGGLE_DISPLAY_CONTACT_FORM: 'contact/toggleDisplayForm',
  TOGGLE_DISPLAY_CONTENT_ROLL: 'contact/toggleDisplayContentRoll',
  LOAD_SLIDER_VIDEOS: 'videos/loadSliderVideos',
  SET_SLIDER_VIDEOS_LOADING_STATUS: 'videos/setSliderVideosLoadingStatus',
  LOAD_MAIN_SLIDER_VIDEOS: 'videos/loadMainSliderVideos',
  SET_MAIN_SLIDER_VIDEOS_LOADING_STATUS: 'videos/setMainSliderVideosLoadingStatus',
};

export const loadCategories = createAction(ActionType.LOAD_CATEGORIES, (categories) => ({
  payload: categories,
}));

export const setCategoriesLoadingStatus = createAction(
  ActionType.SET_CATEGORIES_LOADING_STATUS,
  (status) => ({
    payload: status,
  }),
);

export const loadActors = createAction(ActionType.LOAD_ACTORS, (actors) => ({
  payload: actors,
}));

export const loadDigitalReleases = createAction(ActionType.LOAD_DIGITAL_RELEASES,
  (digitalReleases) => ({
    payload: digitalReleases,
  }));

export const loadSoonReleases = createAction(ActionType.LOAD_SOON_RELEASES,
  (soonReleases) => ({
    payload: soonReleases,
  }));

export const loadBoxOffice = createAction(ActionType.LOAD_BOX_OFFICE,
  (boxOffice) => ({
    payload: boxOffice,
  }));

export const loadNews = createAction(ActionType.LOAD_NEWS, (news) => ({
  payload: news,
}));

export const setNewsLoadingStatus = createAction(ActionType.SET_NEWS_LOADING_STATUS, (status) => ({
  payload: status,
}));

export const loadRecentNews = createAction(ActionType.LOAD_RECENT_NEWS, (news) => ({
  payload: news,
}));

export const setRecentNewsLoadingStatus = createAction(
  ActionType.SET_RECENT_NEWS_LOADING_STATUS,
  (status) => ({
    payload: status,
  }),
);

export const loadNewsEntry = createAction(ActionType.LOAD_NEWS_ENTRY, (newsEntry) => ({
  payload: newsEntry,
}));

export const setNewsEntryLoadingStatus = createAction(
  ActionType.SET_NEWS_ENTRY_LOADING_STATUS,
  (status) => ({
    payload: status,
  }),
);
export const setCurrentCategory = createAction(
  ActionType.SET_CURRENT_CATEGORY,
  (currentCategory) => ({
    payload: currentCategory,
  }),
);

export const setCurrentTrailer = createAction(ActionType.SET_CURRENT_TRAILER, (trailerUrl) => ({
  payload: trailerUrl,
}));

export const resetCurrentTrailer = createAction(ActionType.RESET_CURRENT_TRAILER);

export const toggleDisplayForm = createAction(ActionType.TOGGLE_DISPLAY_CONTACT_FORM);

export const toggleDisplayContentRoll = createAction(ActionType.TOGGLE_DISPLAY_CONTENT_ROLL);

export const loadSliderVideos = createAction(ActionType.LOAD_SLIDER_VIDEOS, (videos, id) => ({
  payload: { videos, id },
}));

export const setSliderVideosLoadingStatus = createAction(
  ActionType.SET_SLIDER_VIDEOS_LOADING_STATUS,
  (status, id) => ({
    payload: { status, id },
  }),
);

export const loadMainSliderVideos = createAction(ActionType.LOAD_MAIN_SLIDER_VIDEOS, (videos) => ({
  payload: videos,
}));

export const setMainSliderVideosLoadingStatus = createAction(
  ActionType.SET_MAIN_SLIDER_VIDEOS_LOADING_STATUS,
  (status) => ({
    payload: status,
  }),
);

export const loadCurrentCategory = createAction(
  ActionType.LOAD_CURRENT_CATEGORY,
  (category) => ({
    payload: category,
  }),
);

export const setCurrentCategoryLoadingStatus = createAction(
  ActionType.SET_CURRENT_CATEGORY_LOADING_STATUS,
  (status) => ({
    payload: status,
  }),
);

export const loadCurrentCategoryVideos = createAction(
  ActionType.LOAD_CURRENT_CATEGORY_VIDEOS,
  (recipes) => ({
    payload: recipes,
  }),
);

export const setCurrentCategoryVideosLoadingStatus = createAction(
  ActionType.SET_CURRENT_CATEGORY_VIDEOS_LOADING_STATUS,
  (status) => ({
    payload: status,
  }),
);

export const resetCurrentCategoryVideos = createAction(ActionType.RESET_CURRENT_CATEGORY_VIDEOS);

export const loadCurrentVideo = createAction(
  ActionType.LOAD_CURRENT_VIDEO,
  (video) => ({
    payload: video,
  }),
);

export const setCurrentVideoLoadingStatus = createAction(
  ActionType.SET_CURRENT_VIDEO_LOADING_STATUS,
  (status) => ({
    payload: status,
  }),
);
