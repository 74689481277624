import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import NewsDetails from '../News-details/News-details';
import { getNewsEntry, getNewsEntryLoadingStatus } from '../../store/selectors';
import { fetchNewsEntry } from '../../store/api-actions';
import { AppRoute, LoadingStatuses } from '../../const';
import { toggleDisplayContentRoll } from '../../store/action';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import GoToTop from '../Go-to-top/Go-to-top';
import LoadingScreen from '../Loading-screen/Loading-screen';

function NewsEntry() {
  const dispatch = useDispatch();
  const { NOT_FOUND } = AppRoute;
  const { id } = useParams();
  const history = useHistory();
  const newsEntry = useSelector(getNewsEntry);
  const newsEntryLoadingStatus = useSelector(getNewsEntryLoadingStatus);
  useEffect(() => {
    dispatch(toggleDisplayContentRoll());
    return () => dispatch(toggleDisplayContentRoll());
  }, [dispatch]);

  useEffect(() => {
    if (newsEntry.id !== id) {
      dispatch(fetchNewsEntry(id, () => history.push(NOT_FOUND)));
    }
  }, [NOT_FOUND, dispatch, history, id, newsEntry.id]);

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <div
        className="page-single"
        style={{
          flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'stretch',
        }}
      >
        {newsEntryLoadingStatus === LoadingStatuses.LOADING && <LoadingScreen />}
        {newsEntryLoadingStatus === LoadingStatuses.LOADED && <NewsDetails newsEntry={newsEntry} />}
      </div>
      <Footer />
      <GoToTop />
    </div>
  );
}

export default NewsEntry;
