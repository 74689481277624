import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-slick';
import MainSlide from '../Main-slide/Main-slide';
import {
  MAXIMUM_SLIDES_COUNT,
  LoadingStatuses,
  MAIN_SLIDES_ID,
} from '../../const';
import { getMainSliderVideos, getMainSliderLoadingStatus } from '../../store/selectors';
import { fetchVideosByID } from '../../store/api-actions';
import LoadingScreen from '../Loading-screen/Loading-screen';

function MainSlider() {
  const dispatch = useDispatch();
  const slides = useSelector(getMainSliderVideos);
  const slidesLoadingStatus = useSelector(getMainSliderLoadingStatus);
  useEffect(() => {
    if (slidesLoadingStatus === LoadingStatuses.INIT) {
      dispatch(fetchVideosByID(MAIN_SLIDES_ID));
    }
  }, [dispatch, slidesLoadingStatus]);

  return (
    <div className="slider movie-items">
      <div className="container">
        <div className="row">
          {slidesLoadingStatus === LoadingStatuses.LOADING && (
            <LoadingScreen />
          )}
          {slidesLoadingStatus === LoadingStatuses.LOADED && (
            <Slider
              className="slick-multiItemSlider"
              dots
              arrows={false}
              infinite
              slidesToShow={MAXIMUM_SLIDES_COUNT}
              slidesToScroll={MAXIMUM_SLIDES_COUNT}
              responsive={[
                {
                  breakpoint: 1215,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  },
                },
                {
                  breakpoint: 1007,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },
                {
                  breakpoint: 783,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
            >
              {slides.map((filmInfo) => (
                <MainSlide
                  filmInfo={filmInfo}
                  key={filmInfo.id}
                />
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
}

export default MainSlider;
